import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DynamicModalComponent } from './dynamic-modal/dynamic-modal.component';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CaptchaModalComponent } from './captcha-modal/captcha-modal.component';
import { RecaptchaModule } from 'ng-recaptcha';
@NgModule({
  declarations: [
    ModalContainerComponent,
    DynamicModalComponent,
    ConfirmationModalComponent,
    CaptchaModalComponent,
    ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    RecaptchaModule
  ],
  exports: [
    ModalContainerComponent,
    DynamicModalComponent,
    ConfirmationModalComponent,
    CaptchaModalComponent,
  ]
})
export class ModalModule { }
