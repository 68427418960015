<header class="app-header">
  <div class="company-header">
    <a class="company-logo" [routerLink]="isAllJobs ? '/jobs' : '/home'">
      <img [src]="jobCompanyLogo || organization.logo" alt="Company logo"/>
    </a>
  </div>

  <nav *ngIf="showNavigationContainer">

    <input type="checkbox"
           id="nav-toggle"
           class="navigation-toggle"
           #navToggleInput
           hidden>

    <label for="nav-toggle" class="nav-toggle-label">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </label>

    <div class="navigation-content">
      <div class="nav-links">
        <a class="navigation-link"
           *ngFor="let route of routes"
           [ngClass]="{
             active: route.active
           }"
           (click)="navigate(navToggleInput, route.link)">{{ route.translationKey | translate }}</a>
        <a *ngIf="organization.link as link"
           class="navigation-link"
           (click)="navigate(navToggleInput)"
           [href]="link"
           target="_blank">{{organization.companyWebsiteLinkButtonName ? organization.companyWebsiteLinkButtonName : ('HEADER.GO_TO_COMPANY_PAGE' | translate)}}</a>
        <span class="saved-jobs-desktop">
          <ng-container [ngTemplateOutlet]="savedJobs"></ng-container>
        </span>
      </div>

      <div class="nav-links social-media-links" *ngIf="showSocialMediaLinks">
        <a [href]="organization.facebookProfileUrl"
           *ngIf="organization.facebookProfileUrl"
           target="_blank"
           class="navigation-link">
          <i class="icon-facebook-plain"></i>
        </a>
        <a [href]="organization.instagramProfileUrl"
           *ngIf="organization.instagramProfileUrl"
           target="_blank"
           class="navigation-link">
          <i class="icon-instagram-plain"></i>
        </a>
        <a [href]="organization.linkedInProfileUrl"
           *ngIf="organization.linkedInProfileUrl"
           target="_blank"
           class="navigation-link">
          <i class="icon-linkedin-plain"></i>
        </a>
        <span appCopyToClipboard
              [appContent]="href"
              class="navigation-link">
          <i class="icon-share"></i>
        </span>
      </div>

      <div class="nav-links language-select">
        <app-language-select></app-language-select>
      </div>

      <span class="saved-jobs-mobile">
        <ng-container [ngTemplateOutlet]="savedJobs"></ng-container>
      </span>

    </div>
  </nav>

  <div *ngIf="showApplyButton"
       class="apply-now">
    <a routerLink="/quiz"
       queryParamsHandling="merge"
       (click)="removeAnswers()"
       class="btn-primary">{{ 'HEADER.APPLY_NOW' | translate }}</a>
  </div>

  <div *ngIf="showJobListButton"
       class="apply-now">
    <a routerLink="/jobs"
       class="btn-primary">{{ 'BUTTONS.BACK_TO_JOB_LIST' | translate }}</a>
  </div>

  <ng-container *ngIf="showInfoButton">
    <div class="info">
      <button (click)="toggleInfoPopup()"
              [ngClass]="{
                'active': infoPopupActive
              }"
              class="btn-icon">
        <i class="icon-info"></i>
      </button>
    </div>

    <div *ngIf="infoPopupActive"
         appOutsideClick
         (outsideClick)="toggleInfoPopup()"
         class="info-popup">
      <div class="info-popup-content">
        <span>{{ infoPopupContentTranslationKey | translate}}</span>
      </div>
    </div>
  </ng-container>

  <app-progress-bar *ngIf="showProgressBar"></app-progress-bar>
</header>

<ng-template #savedJobs>
  <a class="navigation-link saved-jobs-link"
     [routerLink]="savedJobsTotal === 0 ? '/express-interest' : '/saved-jobs'">
    <div class="saved-jobs-indicator">
      <i [attr.data-content]="savedJobsTotal"
         [ngClass]="{
           'icon-icon-heart': !!savedJobsTotal,
           'icon-heart-outline': !savedJobsTotal,
           'jobs-count': !!savedJobsTotal
         }">
      </i>
    </div>
  </a>
</ng-template>

