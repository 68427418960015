<div class="lang-select-container" *ngIf="nativeLanguage.iso6391 !== 'en' && !isEnterprise">
  <button type="button"
          role="button"
          class="btn-lang btn-secondary"
          [ngClass]="{'active': activeLanguage === nativeLanguage.iso6391}"
           (click)="changeLanguage(nativeLanguage.iso6391)">{{ nativeLanguage.iso6392 }}</button>
  <div class="divider"></div>
  <button type="button"
          role="button"
          class="btn-lang btn-secondary"
          [ngClass]="{'active': activeLanguage === 'en'}"
          data-lang="en"
          (click)="changeLanguage('en')">ENG</button>
</div>
<div *ngIf="isEnterprise || isAllJobs">
  <select class="language-select"
          (change)="changeLanguage(activeLanguage)"
          [(ngModel)]="activeLanguage">
    <option *ngFor="let language of possibleLanguages"
            [value]="language.iso6391">{{ language.name[activeLanguage] }}</option>
  </select>
</div>
