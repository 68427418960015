import { LoaderService } from './services/loader.service';
import { CompanyService } from 'src/app/services/company.service';
import { ErrorHandlingService } from './services/error-handling.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationStart, RouterOutlet, ActivatedRoute, NavigationExtras, Params, RouterEvent } from '@angular/router';
import { AppConfigService } from './services/app-config.service';
import { checkForQueryParams, readQueryParameters } from './shared-functions';
import { catchError, filter } from 'rxjs/operators';
import { slideInAnimation } from './router-animations';
import { TranslateService } from '@ngx-translate/core';
import { JobApplication } from './models/job-application.model';
import { CheckDeviceService } from './services/check-device.service';
import { ModalService } from './services/modal.service';
import { ConfirmationModalData } from './models/modal.interface';
import { ConfirmationModal } from './classes/modal.class';
import { QUIZ_TYPES } from './resources/quiz-types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit {

  activePage: string;

  showSendLinkContainer = false;

  constructor(
    private appConfigService: AppConfigService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private errorHandlingService: ErrorHandlingService,
    private companyService: CompanyService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private checkDevice: CheckDeviceService,
    private modalService: ModalService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(({url, navigationTrigger}: NavigationStart) => {
        const routerUrl = this.router.url;

        if (navigationTrigger === 'popstate' && (routerUrl.includes('quiz') || routerUrl.includes('video')) && !routerUrl.includes('kq')) {
          return;
        }

        this.handleRouteParams(url);

        this.showSendLinkContainer = false;

        if (url.includes('quiz') || url.includes('video')) {
          this.activePage = 'quiz';
          this.showSendLinkContainer = !this.checkDevice.isMobile() && !!readQueryParameters('application');
        } else if (
          url.includes('jobs')             ||
          url.includes('job-details')      ||
          url.includes('why-work-with-us') ||
          url.includes('whistle-blower')   ||
          url.includes('express-interest') ||
          url.includes('saved-jobs')       ||
          url.includes('home')             ||
          url.includes('notInterested')    ||
          url === '/'
        ) {
          this.activePage = 'company';
        } else if (
          url.includes('application-start')         ||
          url.includes('register')                  ||
          url.includes('registration-notification') ||
          url.includes('register-interest')
        ) {
          this.activePage = 'application';
        } else if (url.includes('application-complete')) {
          this.activePage = 'application-complete';
        } else {
          this.activePage = null;
        }
      });

  }

  ngOnInit(): void {
    const { organization, jobApplication, inactiveJob, candidateDataErased } = this.appConfigService.config;

    this.renderer.setStyle(
      document.documentElement,
      '--color-primary',
      organization.primaryColor,
      2
    );

    if (organization.useLandingPage) {
      this.renderer.setStyle(
        document.documentElement,
        '--color-button-primary',
        organization.landingPage.buttonTextColor,
        2
      );
    }

    if (inactiveJob) {
      this.router.navigate(['/jobs']);
      this.toastr.error(
        this.translateService.instant('COMPANY.INACTIVE_JOB')
      );

      return;
    }

    if (candidateDataErased) {
      this.router.navigate(['/jobs']);
      this.toastr.error(
        this.translateService.instant('COMPANY.DATA_DELETED')
      );

      return;
    }

    if (readQueryParameters('application')) {

      if (window.location.href.includes('universal-job-update')) {
        return;
      }

      const followUpAnswerIndex = readQueryParameters('following');

      const queryParams: Params = { application: jobApplication.guid, following: followUpAnswerIndex ? followUpAnswerIndex : null };
      const navExtras: NavigationExtras = { queryParams, queryParamsHandling: 'merge' };

      if (jobApplication.applicationComplete) {
        this.router.navigate(['/application-complete'], navExtras);
        return;
      }

      const activeModuleIndex = jobApplication.currentQuestionsModule;
      const activeModule = jobApplication.orderOfQuestionsModules[activeModuleIndex];

      if (activeModule === QUIZ_TYPES.BUSINESS_QUESTIONS || activeModule === QUIZ_TYPES.PERSONALITY_TEST) {
        this.router.navigate(['quiz'], navExtras);
      } else if (activeModule === 'videoQuestions') {
        this.router.navigate(['video'], navExtras);
      }
    }
  }

  handleRouteParams(url: string): void {
    const currentLang = this.translateService.currentLang;
    const decodedURL = decodeURIComponent(url);

    if (decodedURL.includes(`/${currentLang}`)) {
      return;
    }

    const {urlWithoutParams, queryParams} = checkForQueryParams(decodedURL);

    const newUrl = `/${currentLang}${urlWithoutParams}`;
    this.router.navigate([newUrl], { queryParams, relativeTo: this.route});
  }

  prepareOutlet(outlet: RouterOutlet): RouterOutlet {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  showHideSendLinkModal(): void {
    const data: ConfirmationModalData = {
      title: 'SEND_LINK.MODAL.TITLE',
      content: 'SEND_LINK.MODAL.CONTENT',
      confirmBtnTitle: 'SEND_LINK.MODAL.SEND_LINK_BTN',
      cancelBtnTitle: 'SEND_LINK.MODAL.CLOSE_MODAL_BTN',
      confirm: () => this.sendLink(),
    };

    this.modalService.addModal(new ConfirmationModal(data));
  }

  sendLink(): void {
    const guid = readQueryParameters('application');

    if (!guid) {
      return;
    }

    const jobApplication = this.appConfigService.config.jobApplication;

    if (jobApplication.numberOfSentApplicationLinkSMSs >= 2) {
      this.toastr.error(
        this.translateService.instant('SEND_LINK.ERRORS.SENT_LINK_COUNT')
      );
      return;
    }

    this.loaderService.show();

    const language = this.translateService.currentLang || this.translateService.defaultLang || 'en';

    this.companyService
      .sendSmsLink(guid, language)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          const errorKeys = Object.keys(errorResponse.error.errors);

          if (errorKeys.indexOf('application') >= 0) {
            this.toastr.error(errorResponse.error.errors.application);
            this.router.navigate(['/jobs']);
          } else {
            return this.errorHandlingService.handleBackendError(errorResponse);
          }
        })
      )
      .subscribe(({numberOfSentApplicationLinkSMSs}: JobApplication) => {
        jobApplication.numberOfSentApplicationLinkSMSs = numberOfSentApplicationLinkSMSs;
        const message = this.translateService.instant('SEND_LINK.TOAST_WILL_RECEIVE_SMS');
        const title = this.translateService.instant('SHARED.SUCCESS');
        this.toastr.success(message, title);
        this.loaderService.hide();
      });
  }
}
