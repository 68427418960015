import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CreateCandidateResponse } from '../../models/candidate.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service';
import { CandidateRegisterService } from '../../services/candidate-register.service';
export abstract class AbstractCandidateRegister {

  candidateRegisterForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,4}$'
        ),
      ],
    ],
    networks: [null, [Validators.required]],
    phone: [
      '',
      [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ],
    ],
    acceptTermsAndConditions: ['', [Validators.requiredTrue]],
    linkedinProfile: [null]
  });

  companyGuid: string;
  termsAndConditions: string;
  candidateData: CreateCandidateResponse;

  protected constructor(
    protected fb: FormBuilder,
    protected router: Router,
    protected toastr: ToastrService,
    protected loaderService: LoaderService,
    protected translateService: TranslateService,
    protected candidateRegisterService: CandidateRegisterService
  ) { }

  abstract createApplication(): void;

  alreadyAppliedForJob(): void {
    sessionStorage.removeItem('jobId');
    sessionStorage.removeItem('jobGuid');
    sessionStorage.removeItem('companyId');
    sessionStorage.removeItem('companyGuid');
    sessionStorage.removeItem('companyDomain');
    sessionStorage.removeItem('jobCompanyLogo');
    this.router.navigate(['/']);
    this.toastr.error(
      this.translateService.instant('ERRORS.ALREADY_FINISHED_APP')
    );
  }
}
