import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CandidateRegisterService } from 'src/app/services/candidate-register.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from 'src/app/classes/organization.class';
import { Country, EnterpriseCompany } from 'src/app/models/company.model';
import { REFERER_TYPES } from 'src/app/resources/referer-types';
import { CheckCandidateApplications, RegisterCandidatePayload } from 'src/app/models/candidate.model';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Application } from 'src/app/classes/application.class';
import { AbstractCandidateRegister } from '../abstract-candidate-register.class';
import { of } from 'rxjs';
import { SetupService } from 'src/app/services/setup.service';
@Component({
  selector: 'app-register-universal-job',
  templateUrl: './register-universal-job.component.html',
  styleUrls: ['./register-universal-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterUniversalJobComponent extends AbstractCandidateRegister implements OnInit {

  companies: EnterpriseCompany[] = [];
  countryRelatedCompaniesWithUniversalJob: EnterpriseCompany[] = [];
  countries: Country[] = [];
  universalJobId: number;
  linkedinProfileValue: string;

  constructor(
    fb: FormBuilder,
    router: Router,
    toastr: ToastrService,
    translateService: TranslateService,
    loaderService: LoaderService,
    candidateRegisterService: CandidateRegisterService,
    private cdr: ChangeDetectorRef,
    private setupService: SetupService,
    private configService: AppConfigService,
    private errorHandlingService: ErrorHandlingService,
    ) {
    super(fb, router, toastr, loaderService, translateService, candidateRegisterService);
  }

  get country(): FormControl {
    return this.candidateRegisterForm.get('country') as FormControl;
  }

  get company(): FormControl {
    return this.candidateRegisterForm.get('company') as FormControl;
  }

  get acceptTermsAndConditions(): FormControl {
    return this.candidateRegisterForm.get('acceptTermsAndConditions') as FormControl;
  }

  ngOnInit(): void {
    this.initializeUniversalJobRegister();
    if (!this.configService.config.organization.isEnterprise) {
      this.linkedinProfileValue = this.configService.config.organization.universalJobs[0].linkedinProfile;
    }
  }

  initializeUniversalJobRegister(): void {
    const { organization } = this.configService.config;

    this.setReferrer(organization);

    if (!organization.isEnterprise) {
      this.universalJobId = organization.universalJobs[0].id;
      this.termsAndConditions = organization.termsAndConditions;
      sessionStorage.setItem('companyGuid', this.configService.config.organization.guid);
      return;
    }

    this.acceptTermsAndConditions.disable();
    this.candidateRegisterForm.addControl('company', this.fb.control(null, [ Validators.required ]));
    this.candidateRegisterForm.addControl('country', this.fb.control(null, [ Validators.required ]));

    this.setUniversalJobCompaniesAndCountries(organization);
  }

  setUniversalJobCompaniesAndCountries(organization: Organization): void {
    this.companies = organization.companies;
    const countries = [];

    this.companies
      .forEach((company: EnterpriseCompany) => {
        if (company.active && company.useUniversalJob) {
          const { country } = company.location;

          if (!countries.find(({id}: Country) => id === country.id)) {
            countries.push(country);
          }
        }
      });

    this.countries = countries;
  }

  setReferrer(organization: Organization): void {
    if (!organization.isEnterprise && this.configService.referrer) {
      this.candidateRegisterService
        .submitReferer(REFERER_TYPES.UNIVERSAL_JOB, this.configService.referrer, organization.universalJobs[0].guid)
        .subscribe();
    }
  }

  checkIfCandidateAlreadyApplied(): void {
    const payload: CheckCandidateApplications = {
      candidateEmail: this.candidateRegisterForm.value.email,
      universalJob: this.universalJobId,
      job: null
    };

    this.loaderService.show();

    this.candidateRegisterService.checkIfCandidateAlreadyApplied(payload)
      .pipe(
        mergeMap(({ guid }: { guid: string }) => {
          if (guid) {
            return this.setupService.getApplicationInfo(guid);
          } else {
            return of(null);
          }
        }),
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      ).subscribe((application: Application) => {
        if (application) {
          if (application.jobApplication.applicationComplete) {
            this.alreadyAppliedForJob();
          } else {
            const { jobApplication, jobInfo } = application;
            this.configService.config.jobApplication = jobApplication;
            this.configService.config.job = jobInfo;

            this.router.navigate(['quiz'],
              {
                queryParams: { application: application.jobApplication.guid }
              }
            );
          }
          this.loaderService.hide();
        } else {
          this.createApplication();
        }
      });
  }

  createApplication(): void {
    const { phone, email, networks, linkedinProfile, name } = this.candidateRegisterForm.value;

    const payload: RegisterCandidatePayload = {
      phone: networks.callingCode + phone,
      email,
      name,
      linkedinProfile,
      universalJobId: this.universalJobId
    };

    const { isEnterprise } = this.configService.organization;

    if (isEnterprise && !sessionStorage.getItem('companyId')) {
      sessionStorage.setItem('companyId', this.company.value.id.toString());
    }

    this.candidateRegisterService
      .createApplication(payload)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        mergeMap(({guid}: {guid: string}) => {
          return this.setupService.getApplicationInfo(guid);
        }),
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      ).subscribe((application: Application) => {
        if (application) {
          const { jobApplication, jobInfo } = application;
          this.configService.config.jobApplication = jobApplication;
          this.configService.config.job = jobInfo;

          this.router.navigate(['application-start'],
            { queryParams: {application: application.jobApplication.guid}}
          );
        }
        this.loaderService.hide();
      });
  }

  countryChanged(): void {
    this.termsAndConditions = '';
    this.company.reset();
    this.acceptTermsAndConditions.reset();
    this.acceptTermsAndConditions.disable();

    const countryRelatedCompaniesWithUniversalJob = [];

    this.companies
      .forEach((company: EnterpriseCompany) => {
        if (company.active && company.location.country.id === this.country.value.id && company.useUniversalJob) {
          countryRelatedCompaniesWithUniversalJob.push(company);
        }
      });

    this.countryRelatedCompaniesWithUniversalJob = countryRelatedCompaniesWithUniversalJob;
  }

  companyChanged(): void {
    const company: EnterpriseCompany = this.company.value;
    this.universalJobId = company.universalJob.id;
    this.termsAndConditions = company.termsAndConditions;
    this.linkedinProfileValue = company.universalJob.linkedinProfile;
    sessionStorage.setItem('companyId', company.id.toString());
    sessionStorage.setItem('companyGuid', company.guid);
    this.acceptTermsAndConditions.enable();
  }
}
