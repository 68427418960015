<h2>{{ 'REGISTER.GREETING' | translate }}</h2>
<p>{{ 'REGISTER.EXPLANATION' | translate }}</p>

<form [formGroup]="candidateRegisterForm"
      (ngSubmit)="onSubmit()">

  <!-- NAME -->
  <div class="form-group form-input-wrapper">
    <input type="text"
           class="form-control"
           formControlName="name"
           placeholder="{{ 'REGISTER.LABELS.NAME' | translate }}">
    <div class="invalid-feedback" *ngIf="name.invalid && name.touched">
      <div *ngIf="name.errors?.required">
        {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
      </div>
    </div>
  </div>

  <!-- EMAIL -->
  <div class="form-group form-input-wrapper">
    <input class="form-control"
           formControlName="email"
           placeholder="{{ 'REGISTER.LABELS.EMAIL' | translate }}">
    <div class="invalid-feedback" *ngIf="email.invalid && email.touched">
      <div *ngIf="email.errors?.required">
        {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
      </div>
      <div *ngIf="email.errors?.pattern">
        {{ 'SHARED.EMAIL_INVALID_ERROR_MSG' | translate }}
      </div>
    </div>
  </div>

  <!-- PHONE_NUMBER -->
  <div class="form-group mobile-phone-group">
    <select formControlName="networks"
            class="form-control custom-select"
            (change)="changedNetwork()">
      <option class="form-control"
              *ngFor="let network of countryCallingCodes"
              [ngValue]="network">
        {{ network.callingCode }}
      </option>
    </select>
    <div class="phone-input">
      <input type="text"
             class="form-control"
             formControlName="phone"
             placeholder="{{ 'REGISTER.LABELS.PHONE' | translate }}">
      <div class="invalid-feedback" *ngIf="phone.invalid && phone.touched">
        <div *ngIf="phone.errors?.required">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
        <div *ngIf="phone.errors?.pattern">
          {{ 'SHARED.PHONE_ERROR_PATTERN_MSG' | translate }}
        </div>
        <div *ngIf="!phone.errors?.pattern && (phone.errors?.minlength || phone.errors?.maxlength)">
          <ng-container *ngIf="(phoneNumberMinLength !== 0) && (phoneNumberMinLength < phoneNumberMaxLength)">
            {{ 'SHARED.PHONE_ERROR_LENGTH_MSG' | translate: {minLength: phoneNumberMinLength, maxLength: phoneNumberMaxLength}  }}
          </ng-container>
          <ng-container *ngIf="phoneNumberMinLength === 0">
            {{ 'SHARED.PHONE_ERROR_LENGTH_MSG_MAX_LENGTH' | translate: {maxLength: phoneNumberMaxLength}  }}
          </ng-container>
          <ng-container *ngIf="phoneNumberMinLength === phoneNumberMaxLength">
            {{ 'SHARED.PHONE_ERROR_LENGTH_MSG_FIXED_LENGTH' | translate: {length: phoneNumberMaxLength}  }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="country">
    <div class="form-group form-input-wrapper">
      <select formControlName="country"
              class="form-control custom-select"
              (change)="changedCountry()"
              [ngClass]="{'default-option': !country.value?.id}">
        <option [value]="null" [selected]="true" [disabled]="true">
          {{ 'REGISTER.PICK_COUNTRY_YOU_SHOW_INTEREST_FOR' | translate }}
        </option>

        <option class="form-control"
                *ngFor="let country of countries"
                [ngValue]="country">
          {{ country.name }}
        </option>
      </select>
      <div class="invalid-feedback" *ngIf="country.invalid && country.touched">
        <div *ngIf="email.errors?.required">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
      </div>
    </div>

    <div class="form-group form-input-wrapper" *ngIf="country?.value?.id">
      <select formControlName="company"
              class="form-control custom-select"
              (change)="changedCompany()"
              [ngClass]="{'default-option': !company.value?.id}">
        <option [value]="null" [selected]="true" [disabled]="true">
          {{ 'REGISTER.PICK_COMPANY_YOU_SHOW_INTEREST_FOR' | translate }}
        </option>

        <option class="form-control"
                *ngFor="let company of countryRelatedCompaniesWithUniversalJob"
                [ngValue]="company">
          {{ company.name }}
        </option>
      </select>
      <div class="invalid-feedback" *ngIf="company.invalid && company.touched">
        <div *ngIf="email.errors?.required">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
      </div>
    </div>
  </ng-container>

    <!-- LINKEDIN_PROFILE -->
    <div *ngIf="linkedinProfileValue" class="form-group form-input-wrapper">
      <input class="form-control"
             formControlName="linkedinProfile"
             placeholder="{{ 'REGISTER.LABELS.LIKEDIN_PROFILE' | translate }}">
      <div class="invalid-feedback" *ngIf="linkedinProfile.invalid && linkedinProfile.touched">
        <div *ngIf="linkedinProfile.errors?.required">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
        <div *ngIf="linkedinProfile.errors?.pattern">
          {{ 'SHARED.INVALID_LINKEDIN_LINK_ERR_MSG' | translate }}
        </div>
      </div>
    </div>

  <div class="form-group tos">
    <label class="custom-checkbox" [ngClass]="{'disabled-tos': !termsAndConditions}">
      <input formControlName="acceptTermsAndConditions"
             type="checkbox" />
      {{ "REGISTER.AGREE_TO_HIGHER" | translate }}
      <span class="checkmark"></span>
    </label>
    <a (click)="openTermsAndConditionsModal()" [ngClass]="{'disabled-tos': !termsAndConditions}">
      {{ 'REGISTER.TOS' | translate }}
    </a>
  </div>

  <div class="btn-group">
    <button type="submit"
            class="btn-secondary"
            [disabled]="candidateRegisterForm.invalid || disableButton">
      {{ 'BUTTONS.START' | translate }}
    </button>
  </div>
</form>
